import React, { Component } from 'react'
import { Route, NavLink } from 'react-router-dom'
// import ActiveLink from '../parts/ActiveLink'

export default class Header extends Component {
    render() {
        return (
            <header aria-label="Header">
                <div className="site-title">
                    Jychung
                </div>
                <nav className="nav" role="navigation" aria-label="Main">
                    {/* <ul>
                        <ActiveLink to='/' label="Home" />
                        <ActiveLink to='/portfolio' label="Portfolio" />
                        <ActiveLink to='/contact' label="Contact" />
                        <ActiveLink to='/blog' label="Blog" />
                    </ul> */}
                    <Route path='/' exact={true} children={ ({match}) => (
                        <NavLink role="listitem" aria-current={match ? "page" : ""} className={`link ${match ? "selected" : ""}`} to='/'>Home</NavLink>
                    )} />
                    <Route path='/portfolio' exact={true} children={ ({match}) => (
                        <NavLink role="listitem" aria-current={match ? "page" : ""} className={`link ${match ? "selected" : ""}`} to='/portfolio'>Portfolio</NavLink>
                    )} />
                    <Route path='/contact' exact={true} children={ ({match}) => (
                        <NavLink role="listitem" aria-current={match ? "page" : ""} className={`link ${match ? "selected" : ""}`} to='/contact'>Contact</NavLink>
                    )} />
                    <Route path='/blog' exact={true} children={ ({match}) => (
                        <NavLink role="listitem" aria-current={match ? "page" : ""} className={`link ${match ? "selected" : ""}`} to='/blog'>Blog</NavLink>
                    )} />
                </nav>
            </header>
        )
    }
}
