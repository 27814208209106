import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import Footer from '../footer/Footer'
import Header from '../header/Header'
import data from '../../config/content.json'
import { NavLink } from 'react-router-dom'
import PropTypes from "prop-types"
import throttle from 'lodash/throttle'
import Decor from '../../img/decor.png'
import LeftArrow from '../icons/LeftArrow'
import Twitter from '../icons/Twitter'
import FacebookCut from '../icons/FacebookCut'

export default class Post extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    };

    state = {
        blog: null,
    }
  
    componentDidMount()  {
        window.scrollTo(0,0);
        window.addEventListener('scroll', 
            throttle(()=> { this.CheckSlide() }, 150)
        )
        if (data) {
            this.setState({
            blog: data.blog
            }) 
        }
    }
  
      componentWillUnmount() {
        window.removeEventListener('scroll', this.CheckSlide());
      }
  
      CheckSlide(e) {
          const sliderImages = document.querySelectorAll('.slide-in');
          sliderImages.forEach( slide => {
            if (window.scrollY + window.innerHeight*.5 > slide.offsetTop)
              slide.classList.add('active');
          })
      }
    render() {
        const { location } = this.props;
        const { blog } = this.state;
        const currentPath = location.pathname.slice(3);
        let pageTitle;
        let pagePath;
        if (blog === null ) return <div className="loading">loading...</div> 
        blog.map( post => post.path === currentPath ? pageTitle = post.title : null)
        blog.map( post => post.path === currentPath ? pagePath = post.path : null)
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={`${pageTitle}`} />
                    <title>{pageTitle} | Jychung</title>
                    <link rel="canonical" href={`http://jychung.com/b/${pagePath}`} />
                </Helmet>
                <img className="decor" src={Decor} alt="" />
                <Header />
                {blog.map( (post, idx) => {
                    return (
                        post.path === currentPath ? 
                        <main key={idx} className="page-wrap-blog">
                            <p className="post-date">{post.date}</p>
                            <h1 className="project-title">{post.title}</h1>
                            <h2 className="page-title">{post.desc}</h2>
                            <section className="post-container">
                                <img src={`../img/${post.img[0]}`} alt={post.alt} />
                                <p className="post-img-desc">{post.imgDesc}</p>
                                {post.content.map( (paragraph, idx) => <p key={idx} className="post-para">{paragraph}</p>)}
                            </section>
                            <div className="share">
                                <a href={`https://twitter.com/intent/tweet?text="${post.title.replace(' ','%20')}"%20—%20%40jychungwebsites%20—%20www.jychung.com/b/${post.path}`}>{Twitter()}</a>
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//www.jychung.com/b/${post.path}`}>{FacebookCut()}</a>
                            </div>
                            <NavLink to="/blog" className="post-link">
                                <p className="post-link-arrow">{ LeftArrow() }Return to blog</p>
                            </NavLink>
                        </main>
                        : null
                    )
                })}
                <Footer /> 
            </>
        )
    }
}
