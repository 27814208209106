import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import Footer from '../footer/Footer'
import Header from '../header/Header'
import Decor from '../../img/decor.png'

export default class Privacy extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content="Jychung takes your privacy seriously. To better protect your privacy I provide this privacy policy notice explaining the way your personal information is collected and used." />
                    <title>Privacy | Jychung</title>
                    <link rel="canonical" href="http://jychung.com/privacy" />
                </Helmet>
                <img className="decor" src={ Decor } alt="background decorative triangle"/>
                <Header />
                <main className="page-wrap">
                    <h2 className="page-title">
                        Privacy Policy<br/>
                        Jychung takes your privacy seriously. To better protect your privacy I provide this privacy policy notice explaining the way your personal information is collected and used.
                    </h2>
                    <section className="privacy">
                        <h3>Collection of Routine Information</h3>
                        <p>This website tracks basic information about their users. This information includes, but is not limited to, IP addresses, browser details, timestamps and referring pages. None of this information can personally identify specific user to this website. The information is tracked for routine administration and maintenance purposes.</p>
                        <h3>Cookies</h3>
                        <p>Where necessary, this website uses cookies to store information about a visitor’s preferences and history in order to better serve the user and/or present the user with customized content.</p>
                        <h3>Advertisement and Other Third Parties</h3>
                        <p>Advertising partners and other third parties may use cookies, scripts and/or web beacons to track users activities on this website in order to display advertisements and other useful information. Such tracking is done directly by the third parties through their own servers and is subject to their own privacy policies. This website has no access or control over these cookies, scripts and/or web beacons that may be used by third parties. Learn how to <a className="link" href="https://policies.google.com/technologies/ads">opt out of Google’s cookie usage</a>.</p>
                        <h3>Security</h3>
                        <p>The security of your personal information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While I strive to use commercially acceptable means to protect your personal information, I cannot guarantee its absolute security.</p>
                        <h3>Changes To This Privacy Policy</h3>
                        <p>This Privacy Policy is effective as of 2019 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>
                        <p>I reserve the right to update or change my Privacy Policy at any time and you should check this Privacy Policy periodically. If I make any material changes to this Privacy Policy, I will notify you either through the email address you have provided, or by placing a prominent notice on my site.</p>
                        <h3>Contact Information</h3>
                        <p>For any questions or concerns regarding the privacy policy, please send me an email to <a className="link" href="mailto:jychung.com@gmail.com">jychung.com@gmail.com</a>.</p>
                    </section>
                </main>
                <Footer />
            </>
        )
    }
}
