import React, { Component } from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import {Helmet} from "react-helmet"
import data from '../../config/content.json'
import throttle from 'lodash/throttle'
import RightArrow from '../icons/RightArrow'
import { NavLink } from 'react-router-dom'
import Twitter from '../icons/Twitter'
import FacebookCut from '../icons/FacebookCut'

export default class Blog extends Component {
    constructor() {
        super();
        this.state = {
            showShare: false,
            blog: null
        };
        this.showShare = this.showShare.bind(this);
      }
      
      showShare(event) {
        event.preventDefault();
        
        this.setState({ showShare: true }, () => {
          document.addEventListener('click', this.closeShare);
        });
      }
      closeShare(event) {
        if ( this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
          this.setState({ showShare: false }, () => {
            document.removeEventListener('click', this.closeShare);
          });
          
        }
      }
    // state = {
    // }

    componentDidMount()  {
        window.scrollTo(0,0);
        window.addEventListener('scroll', 
            throttle(()=> { this.CheckSlide() }, 150)
        )
        if (data) {
            this.setState({
            blog: data.blog
            }) 
        }
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.CheckSlide());
    }

    CheckSlide(e) {
        const sliderImages = document.querySelectorAll('.slide-in');
        sliderImages.forEach( slide => {
          if (window.scrollY + window.innerHeight*.5 > slide.offsetTop)
            slide.classList.add('active');
        })
    }
  
    render() {
        const { blog, showShare } = this.state;
        if (blog === null ) return <div className="loading">loading...</div>
        let pathsList = [];
        blog.map( blog => pathsList.push(`${blog.path}`));
        // console.log(checkIfDuplicateExists(pathsList))
        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
        return (
            <>
                {findDuplicates(pathsList).length >= 1 ? console.log(`⚠️ Duplicate Path: ${findDuplicates(pathsList)}`) : null}
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content="Blogs are the mind's melting pot of thoughts and things, and an open door to the mind's scattered {/*thoughts, ideas, andstuff and things*/} clutter ogranized into simple posts. This blog is a {/*scattered*/} mess of unorganized thoughts, ideas, and stuff. If I could be more descriptively vague about this page I would." />
                    <title>Blog | Jychung</title>
                    <link rel="canonical" href="http://jychung.com/blog" />
                </Helmet>
                <Header />
                <main className="page-wrap">
                    <h2 className="page-title">
                        Blogs are the mind's melting pot of thoughts and things, and an open door to the mind's scattered {/*thoughts, ideas, andstuff and things*/} clutter ogranized into simple posts. This blog is a {/*scattered*/} mess of unorganized thoughts, ideas, and stuff. If I could be more descriptively vague about this page I would. 
                    </h2>
                    <section className="card-container">
                        {blog.map( (post, idx) => {
                            return (
                                <div className="post slide-in" key={idx}>
                                    <NavLink className="post-link" to={`/b/${post.path}`}>
                                        <img src={`./img/${post.img[0]}`} alt={post.alt} />
                                    </NavLink>
                                    <div className="post-content">
                                        <h3>{post.title}</h3>
                                        <p className="post-build">{post.desc}</p>
                                    </div>
                                    <NavLink className="post-link" to={`/b/${post.path}`}>
                                        <p className="post-link-arrow">Read more{ RightArrow() }</p>
                                    </NavLink>
                                    <div className="share box">
                                        <a href={`https://twitter.com/intent/tweet?text="${post.title.replace(' ','%20')}"%20—%20%40jychungwebsites%20—%20www.jychung.com/b/${post.path}`}>{Twitter()}</a>
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//www.jychung.com/b/${post.path}`}>{FacebookCut()}</a>
                                    </div>
                                </div>
                            )
                        })}
                    </section>
                </main>
                <Footer />
            </>
        )
    }
}
