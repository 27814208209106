import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import Footer from '../footer/Footer'
import Header from '../header/Header'

export default class Error extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>404 Error | Jychung</title>
                    {/* <link rel="canonical" href="http://jychung.com" /> */}
                </Helmet>
                <Header />
                <main className="page-wrap">
                    <h2 className="page-title">
                        404<br/>
                        Page not found
                    </h2>
                </main>
                <Footer />
            </>
        )
    }
}
