import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import Footer from '../footer/Footer'
import Header from '../header/Header'

export default class Contact extends Component {
    state = {
      clientName : '',
      clientEmail : '',
      clientMessage : '',
      busy : false,
      sent : false,
      typingName : () => {},
      step : 1
    }
  
    componentDidMount() {
        window.scrollTo(0,0);
    }

    submitHandler = (evt) => {
      this.setState(
            {
                busy : true,
                submit: true
            }
          );
      evt.preventDefault();
      const errorMessage = this.validateEmail();
      console.log(errorMessage)
  
      if (errorMessage !== '')
          return errorMessage;
  
      const url = `${process.env.PUBLIC_URL}/php/email.php`;
  
  
      let data = {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              type : 'inquiry',
              clientName : document.getElementsByClassName('js-clientName')[0].value,
              clientEmail : document.getElementsByClassName('js-clientEmail')[0].value,
              clientMessage : document.getElementsByClassName('js-clientMessage')[0].value
          })
      }
      console.log(data)
      fetch(url, data)
      .then((responseJson) => {
          return responseJson.json();
      })
      .then((text) => {
          switch(text) {
              case 1:
                  this.setState({sent : true, busy: false});
                  break;
              default:
                  break;
          }
          
      })
      .catch((error) => {
          this.setState({sent : false, busy: false});
          console.log(error);
      });
    }
  
    validateName = () => {
      const { clientName } = this.state;
      // name test
      // const rx = new RegExp("[a-zA-Z]");
      var reg = new RegExp(/[~`!#$%^&*+=[\]\\';,/{}|\\":<>?]/);
      if (clientName.length <= 1)
          return 'Name should be longer';
      else if (reg.test(clientName))
          return 'No special characters';
      else
          return '';
    }
  
    validateEmail = () => {
        const clientEmail = document.getElementsByClassName('js-clientEmail')[0].value;
        // name test
        console.log(clientEmail)
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (clientEmail.length <= 2)
            return 'Email should be longer';
        else if (!re.test(String(clientEmail).toLowerCase()))
            return 'Invalid Email';
        else
            return '';
    }
    render() {
        const { submit, sent }  = this.state;
    
        const renderSubmitLabel = submit ? ( sent ? 'Thank you!' : 'Sending...' ) : 'Submit';
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content="Have any questions or comments? You can send those here." />
                    <title>Contact | Jychung</title>
                    <link rel="canonical" href="http://jychung.com/contact" />
                </Helmet>
                <Header />
                <section className="contact-methods">
                    <p>Email: <a className="link" href="mailto:jychung.com@gmail.com">jychung.com@gmail.com</a></p>
                    <p>Phone: <a className="link" href="tel:1-213-478-1158">+1.213.478.1158</a> (call/text)</p>
                </section>
                <main className="contact-container page-wrap" id="contact">
                    {/* <h2 className="page-title">Contact</h2> */}
                    { !sent ?
                        <div className="contact-form">
                            <h2 className="c-contact__prompt page-title">Have any questions or comments? You can send those here.</h2>
                            <form className={`c-contact__form`} onSubmit={this.submitHandler}>
                                <label className="c-contact__label" htmlFor="clientName">
                                    Name:
                                    <input className="c-contact__inputText js-clientName" type="text" id="clientName" name="clientName" placeholder="Your Name" autoComplete="off"/>
                                </label>
                                <label className="c-contact__label" htmlFor="clientEmail">
                                    Email:
                                    <input className="c-contact__inputText js-clientEmail" type="email" id="clientEmail" name="clientEmail" placeholder="Your Email Address" />
                                </label>
                                <label className="c-contact__label" htmlFor="clientMessage">
                                    Message:
                                    <textarea className="c-contact__inputText js-clientMessage" type="message" id="clientMessage" name="clientMessage" placeholder="Your Message" />
                                </label>
                                <button onClick={this.submitHandler} className="c-contact__submit button" type="button">{renderSubmitLabel}</button>
                            </form>
                        </div> 
                        : 
                        <div className="contact-no-form">
                            <h2 className="page-title">Thanks for message!</h2>
                            <p>I will return your message as soon as I can.</p>
                        </div>
                    }
                </main>
                <Footer />
            </>
        )
    }
}
