import React, { Component } from 'react'
import Linkedin from '../icons/Linkedin'
import Instagram from '../icons/Instagram'
import Facebook from '../icons/Facebook'
import { NavLink } from 'react-router-dom'
import Twitter from '../icons/Twitter'

export default class Footer extends Component {
    render() {
        const today = new Date();
        const year = today.getFullYear();
        return (
            <footer aria-label="Footer">
                <div className="social">
                    <div className="social-container">
                        <div className="copyright">
                            { year } © Jychung Websites <NavLink className="link" to="/privacy">Privacy</NavLink>
                        </div>
                        <div>
                            <a title="Linkedin Jychung" href="https://www.linkedin.com/in/jychung/" target="_blank" rel="noopener noreferrer">{ Linkedin() }</a>
                            <a title="Twitter Jychung" href="https://twitter.com/jychungwebsites" target="_blank" rel="noopener noreferrer">{ Twitter() }</a>
                            <a title="Instagram Jychung" href="https://www.instagram.com/jychung_websites/" target="_blank" rel="noopener noreferrer">{ Instagram() }</a>
                            <a title="Facebook Jychung" href="https://fb.me/jychung" target="_blank" rel="noopener noreferrer">{ Facebook() }</a>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
