import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import './App.scss';
import Home from './components/home/Home'
import NoMatch from './components/error/Error'
import Contact from './components/contact/Contact'
import Portfolio from './components/portfolio/Portfolio'
import Blog from './components/blog/Blog'
import Privacy from './components/privacy/Privacy'
import Project from './components/project/Project'
import Post from './components/post/Post';

export default class App extends Component {
  render() {
    console.log(`🅹🆈🅲🅷🆄🅽🅶.🅲🅾🅼`);
    console.log('Wow, thanks for inspecting the good parts. 😊 Interested in hiring me? Or want to chat about an idea? Send me an email at jychung.com@gmail.com');
    return (
      <>
        <Router>
            <Switch>
              <Route exact path="/" component={ Home } />
              <Route path="/portfolio" component={ Portfolio }/>
              <Route path="/project/:projectID" component={ Project } />
              <Route path="/contact" component={ Contact }/>
              <Route path="/blog" component={ Blog }/>
              <Route path="/b/:postID" component={ Post } />
              <Route path="/privacy" component={ Privacy }/>
              <Route component={ NoMatch } />
            </Switch>
        </Router>
      </>
    )
  }
}
