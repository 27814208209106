import React, { Component } from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import data from '../../config/content.json'
import throttle from 'lodash/throttle'
import { NavLink } from 'react-router-dom'
import RightArrow from '../icons/RightArrow'
import {Helmet} from "react-helmet"

export default class Portfolio extends Component {
    state = {
      cards: null
    }

    componentDidMount()  {
        window.scrollTo(0,0);
        window.addEventListener('scroll', 
            throttle(()=> { this.CheckSlide() }, 150)
        )
        if (data) {
            this.setState({
            cards: data.cards
            }) 
        }
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.CheckSlide());
    }

    CheckSlide(e) {
        const sliderImages = document.querySelectorAll('.slide-in');
        sliderImages.forEach( slide => {
          if (window.scrollY + window.innerHeight*.5 > slide.offsetTop)
            slide.classList.add('active');
        })
    }
  
    render() {
        const { cards } = this.state;
        if (cards === null ) return <div className="loading">loading...</div> 
        let portfolioPathList = [];
        cards.map( card => portfolioPathList.push(`${card.path}`))
        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
        return (
            <>
                {findDuplicates(portfolioPathList).length >= 1 ? console.log(`⚠️ Duplicate Path: ${findDuplicates(portfolioPathList)}`) : null}
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content="All my websites are custom built to facilitate online communication between my clients and their audience. Each project is built from scratch with a great deal of work - researching, wireframing, designing, developing, and testing.  My goal is to empower everyday entrepreneurs with beautiful UI/UX and develop features (like a content management system) one website at a time." />
                    <title>Portfolio | Jychung Websites</title>
                    <link rel="canonical" href="http://jychung.com/portfolio" />
                </Helmet>
                <Header />
                <main className="page-wrap">
                    <h2 className="page-title">
                        All my websites are custom built to facilitate online communication between my clients and their audience.
                        Each project is built from scratch with a great deal of work&mdash;researching, wireframing, designing, developing, and testing. 
                        My goal is to empower everyday entrepreneurs with beautiful UI/UX and develop features (like a content management system) one website at a time.
                    </h2>
                    <section className="card-container">
                        {cards.map( (card, idx) => {
                            return (
                                <div className="card slide-in" key={idx}>
                                    <h3 className="card-title">{card.title}</h3>
                                    <p className="card-build">{card.build}</p>
                                    <NavLink className="card-link slide-in" to={`/project/${card.path}`}>
                                        <img src={`./img/${card.mockDesk[0]}`} alt={card.alt} />
                                        <img src={`./img/${card.mockMob[0]}`} alt={card.alt} />
                                        <p className="link card-link-arrow">See project{ RightArrow() }</p>
                                    </NavLink>
                                </div>
                            )
                        })}
                    </section>
                </main>
                <Footer />
            </>
        )
    }
}
