import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import Footer from '../footer/Footer'
import Header from '../header/Header'
import data from '../../config/content.json'
import { NavLink } from 'react-router-dom'
import PropTypes from "prop-types"
import Link from '../icons/Link'
import throttle from 'lodash/throttle'
import Decor from '../../img/decor.png'
import LeftArrow from '../icons/LeftArrow'

export default class Project extends Component {
    static propTypes = {
      location: PropTypes.object.isRequired
    };

    state = {
        cards: null
    }

    componentDidMount()  {
        window.scrollTo(0,0);
        window.addEventListener('scroll', 
            throttle(()=> { this.CheckSlide() }, 150)
        )
        if (data) {
            this.setState({
            cards: data.cards
            }) 
        }
    }

    componentWillUnmount() {
    window.removeEventListener('scroll', this.CheckSlide());
    }

    CheckSlide(e) {
        const sliderImages = document.querySelectorAll('.slide-in');
        sliderImages.forEach( slide => {
        if (window.scrollY + window.innerHeight*.4 > slide.offsetTop)
            slide.classList.add('active');
        })
    }
    render() {
        const { location } = this.props;
        const { cards } = this.state;
        const currentPath = location.pathname.slice(9);
        let pageTitle;
        let pagePath;
        if (cards === null ) return <div className="loading">loading...</div> 
        cards.map( project => project.path === currentPath ? pageTitle = project.title : null)
        cards.map( project => project.path === currentPath ? pagePath = project.path : null)
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={`Check out this website - ${pageTitle}`} />
                    <title>{pageTitle} | Jychung</title>
                    <link rel="canonical" href={`http://jychung.com/project/${pagePath}`} />
                </Helmet>
                <img className="decor" src={ Decor } alt=""/>
                <Header />
                {cards.map( (card, idx) => {
                    return (
                        card.path === currentPath ? 
                        <main key={idx} className="page-wrap">
                            <h1 className="project-title">{card.title}</h1>
                            <h2 className="page-title">{card.desc}</h2>
                            {card.link ? 
                                <div className="page-link">
                                    <a className="link" href={card.link} target="_blank" rel="noopener noreferrer">View page{Link()}</a>
                                </div>
                                : null
                            }
                            <section>
                                <div className="img-container">
                                    {card.mockDesk.map( (deskMock, idx) => <img key={idx} className="slide-in" src={`../img/${deskMock}`} alt={card.alt} />)}
                                </div>
                                <div className="img-container-small">
                                    {card.mockMob.map( (deskMock, idx) => <img key={idx} className="slide-in" src={`../img/${deskMock}`} alt={card.alt} />)}
                                </div>
                            </section>
                            <p className="page-desc">
                                {card.build}.
                                {card.link ? 
                                    <span className="page-link">&nbsp;<a className="link" href={card.link}>View page{Link()}</a></span>
                                    : null
                                }
                            </p>
                        </main>
                        : null
                    )
                })}
                <div className="page-link">
                    <NavLink to="/portfolio" className="link">{ LeftArrow() }Return to portfolio</NavLink>
                </div>
                <Footer /> 
            </>
        )
    }
}
