import React, { Component } from 'react'
import {Helmet} from "react-helmet"
import { NavLink } from 'react-router-dom'
import Footer from '../footer/Footer'

export default class Home extends Component {

    componentDidMount()  {
        window.scrollTo(0,0);
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content="Hello, I'm Jenny Chung, and I'm a web developer. I love to build immersive web applications with beautiful intuitive designs." />
                    <title>Jychung Websites</title>
                    <link rel="canonical" href="http://jychung.com" />
                </Helmet>
                <div className="hero">
                    <div className="hero-left-panel">
                        <div className="hero-img"></div>
                    </div>
                    <div className="hero-right-panel">
                        <main className="hero-content" role="main">
                            <h1 className="hero-title">Jychung</h1>
                            <h2 className="hero-desc">
                                Hello, I'm Jenny Chung, and I'm a web developer. 
                                I love to build immersive web applications with beautiful intuitive designs.
                            </h2>
                            <nav className="hero-links" role="navigation">
                                <NavLink role="listitem" className="link" to='/portfolio'>Portfolio</NavLink>
                                <NavLink role="listitem" className="link" to='/contact'>Contact</NavLink>
                                <NavLink role="listitem" className="link" to='/blog'>Blog</NavLink>
                            </nav>
                            <p>
                                {/* I first took an interest in web design and development when I attended a Women in Tech meetup hosted by Media Temple.  */}
                                My first encounter with code was at a meetup for novice developers.
                                As someone who had never touched a line of code I was fascinated to see someone translate their ideas into code. 
                                Enticed by the creative and innovative potential I found the whole experience enjoyable despite fumbling through lines of nonsensical character combinations.
                                {/* I was enticed by the potential to create and innovate that despite fumbling through lines of nonsensical character combinations I found the whole experience enjoyable. */}
                                {/* Despite fumbling through lines of nonsensical character combinations I found the whole experience enjoyable, because of the potential to create and innovate. */}
                            </p>
                            <p>
                                {/* That event in 2017 sparked a growing interest in coding.  */}
                                {/* With a freshly sparked interest in coding */}
                                {/* A few months later I pursued web development seriously and a year later I opened up my own business creating websites.  */}
                                Soon after I pursued web development seriously and a year later I opened up my own business creating websites. 
                                And I love what I do <NavLink className="link" to="/portfolio">(see my work)</NavLink>.
                            </p>
                            <p>
                                If I'm not in front of my computer building my next idea, 
                                then you can find me doing what I love to do: 
                                hiking a trail or climbing some rocks, 
                                reading or listening to a book, 
                                or chatting away with friends.
                            </p>
                            <p>
                                Well, enough about me.
                                Are you interested in creating something on the world wide web? 
                                Make your next move by <NavLink className="link" to="/contact">getting in touch</NavLink>, and we can make it happen.
                            </p>
                        </main>
                        <Footer />
                    </div>
                </div>

            </>
        )
    }
}